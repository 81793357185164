.email-verification-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10%;
    height: 100vh;
    background-color: #ffffff;
  }
  
  .email-verification-box {
    width: 300px;
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .email-verification-box h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .email-verification-box p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.6;
  }
  
  .verify-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 20px 0;
    cursor: pointer;
  }
  
  .link-instruction {
    margin: 20px 0 10px 0;
  }
  
  .verification-link {
    background-color: #ffffff;
    padding: 8px;
    word-wrap: break-word;
  }
  
  .social-icons {
    margin: 20px 0;
  }
  
  .social-icons span {
    padding: 0 10px;
  }
  
  .footer {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
  }
  
  /* EmailVerification.module.css */
.alert-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.retry-button {
  background-color: #007bff;
  border-color: #007bff;
  margin-top: 10px;
}

.link-message {
  margin-top: 10px;
}

.link-style {
  color: #007bff;
}
