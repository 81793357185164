.custom-table-container {
    width: 100%;
    max-height: 45vh;
    overflow-y: auto;
    border: 1px solid #ddd;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.85rem;
  }
  
  .custom-table th, .custom-table td {
    padding: 6px 8px;
    text-align: left;
    border-bottom: 1px solid #DCDCDC;
    line-height: 1.2;
  }
  
  .custom-table thead {
    background-color: #DCDCDC;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .custom-table th {
    background-color: #ffffff;
    color: #1F1F20;
    font-size: 0.9rem;
  }
  
  .section-row td {
    font-weight: bold;
    background-color: #ffffff;
    color: #1F1F20;
    font-size: 0.85rem;
  }
  
  @media (max-width: 768px) {
    .custom-table {
        font-size: 0.75rem;
    }
    
    .custom-table th {
        font-size: 0.8rem;
    }
    
    .custom-table th, .custom-table td {
        padding: 4px 6px;
    }
  }
  