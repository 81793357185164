.subscriptions-container {
  width: 100%;
  background: white;
  border-radius: 8px;
}

.subscriptions-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.subscriptions-table th,
.subscriptions-table td {
  padding: 16px 24px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  vertical-align: middle;
}

.subscriptions-table th {
  font-weight: 500;
  color: #344767;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.02);
}

.subscriptions-table td {
  color: #344767;
  font-size: 16px;
  height: 72px;
}

.subscriptions-table tr:last-child td {
  border-bottom: none;
}

.subscriptions-table th:first-child,
.subscriptions-table td:first-child {
  width: 45%;
  text-align: left;
}

.subscription-name {
  display: flex;
  align-items: center;
  gap: 12px;
}

.subscription-name span {
  text-transform: capitalize;
}

.subscriptions-table th:nth-child(2),
.subscriptions-table td:nth-child(2) {
  width: 15%;
}

.subscriptions-table th:nth-child(3),
.subscriptions-table td:nth-child(3) {
  width: 40%;
}

.map-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  width: fit-content;
  margin: 0 auto;
  background-color: #1A73E8;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.map-button:hover {
  background-color: #1557B0;
  color: white;
}

.map-icon {
  font-size: 16px;
}

.subscriptions-table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
  transition: background-color 0.2s ease;
}

.no-subscriptions {
  text-align: center;
  padding: 24px;
  font-size: 1.25rem;
  color: #344767;
}
